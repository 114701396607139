import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/logo-certyfikat-iso.jpg";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";

export const query = graphql`
  {
    zdjecie1: file(relativePath: { eq: "ISO-pl-27001.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Wdrozenieiso27001 = ({ data }) => {
  return (
    <Artykul
      title="Questy z certyfikatem ISO/IEC 27001"
      keywords={["norma ISO 27001"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Certyfikat bezpieczeństwa ISO 27001 przyznany Questy"
      metaTitle="Questy z certyfikatem ISO/IEC 27001"
      metaDescription="Questy uzyskało certyfikat ISO 27001, poświadczający spełnianie najwyższych norm bezpieczeństwa informacji ✅ Dlaczego to takie ważne? ✅ Jak skorzystają z tego klienci?"
    >
      <h2
        style={{
          textAlign: "left",
        }}
      >
        Najwyższe normy bezpieczeństwa informacji
      </h2>
      <p>
        Z dumą możemy już oficjalnie ogłosić, iż nasza firma otrzymała
        certyfikat bezpieczeństwa <strong>ISO/IEC 27001</strong>! Oznacza to, że
        stosowane przez nas procedury zabezpieczenia przepływu informacji,{" "}
        <strong>spełniają najwyższe, międzynarodowe standardy.</strong>
      </p>
      <br />
      <h2>Dlaczego to takie ważne?</h2>
      <p>
        W obecnych czasach dostęp do informacji jest jednym z kluczowych
        zasobów. Odpowiednie zabezpieczenie przed potencjalnymi zagrożeniami to
        podstawowy warunek, który musi być spełniony, aby nie trafiły one
        w&nbsp;niepowołane ręce. Zwłaszcza w firmach takich jak nasza, gdzie
        tworzy się narzędzia usprawniające przepływ danych w przedsiębiorstwie,
        kładzie się na to szczególny nacisk.{" "}
        <strong>
          Dlatego, chcąc by nasi klienci mieli pewność, że działamy zgodnie z
          najwyższymi standardami, zaczęliśmy ubiegać się o uzyskanie
          certyfikatu.
        </strong>{" "}
        Często przechowujemy ich poufne dane, zatem musimy dokładać wszelkich
        starań, by obiegały wyłącznie po ściśle wytyczonych ścieżkach.
      </p>
      <br />

      <h2>Co oznacza posiadanie takiego certyfikatu?</h2>
      <p>
        Uzyskanie formalnego potwierdzenia o tym, iż{" "}
        <strong>
          tworząc oprogramowanie działamy przy zachowaniu wszelkich najlepszych
          praktyk
        </strong>
        , było dla nas kluczowe. Chcieliśmy zapewnić naszych klientów o jakości
        usług, które dla nich świadczymy. Na przestrzeni kilku miesięcy
        uporządkowaliśmy <strong>strategię zarządzania bezpieczeństwem </strong>
        informacji wewnątrz Questy. Objęła ona takie aspekty jak:
      </p>

      <p>
        <ul>
          <li>
            <strong>Klasyfikacja rodzajów</strong> przetwarzanych informacji;
          </li>
          <li>
            Ustalenie zasad korzystania z{" "}
            <strong>zasobów informatycznych</strong> przez pracowników;
          </li>
          <li>
            Stworzenie schematu zarządzania incydentami{" "}
            <strong>bezpieczeństwa informacji</strong>;
          </li>
          <li>
            Określenie <strong>osób odpowiedzialnych</strong> za poszczególne
            obszary;
          </li>
          <li>
            Przygotowanie <strong>scenariuszy działania</strong> przy
            potencjalnym wystąpieniu incydentów;
          </li>
          <li>
            Opracowanie strategii zachowania{" "}
            <strong>ciągłości przepływu danych.</strong>
          </li>
        </ul>
      </p>
      <br />
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie1]}
        alts={["Wdrożenie normy ISO 27001."]}
      />
      <br />
      <h2>Jak skorzystają z tego nasi klienci?</h2>
      <p>
        <strong>Posiadanie certyfikatu ISO/IEC 2700</strong> przez dostawcę
        oprogramowania powinno zainteresować przede wszystkim naszych klientów.
        Dlaczego? Bo otrzymują najlepszy dowód na to, że przechowywane przez nas
        ich dane są <strong>w pełni bezpieczne.</strong>
      </p>

      <p>
        <ul>
          <li>
            Spełnienie <strong>wymogów prawnych</strong> w zakresie ochrony
            danych.
          </li>
          <li>
            Zminimalizowane ryzyko wystąpienia incydentów zaburzających{" "}
            <strong>przepływ danych</strong> wewnątrz firmy.
          </li>
          <li>
            Wypracowane, <strong>gotowe scenariusze działania</strong>,
            pozwalające na błyskawiczną reakcję w przypadku pojawienia się
            potencjalnego błędu.
          </li>
        </ul>
      </p>
      <br />
      <h2>Certyfikat...i co dalej?</h2>
      <p>
        Samo otrzymanie poświadczenia w formie certyfikatu o spełnianych przez
        nas normach,
        <strong>
          {" "}
          to nie koniec prac w&nbsp;zakresie bezpieczeństwa. Wprost przeciwnie -
          jest to dokument, który stanowi żywy element kultury organizacji i
          rozwija się razem z nią.
        </strong>{" "}
        Zatem, bogatsi o nowe doświadczenia, będziemy cały czas udoskonalać
        nasze procesy, poprawiając jakość oferowanych klientom usług. Co więcej,
        nasze oprogramowanie tworzymy w oparciu o tę właśnie zdobytą wiedzę. Co
        za tym idzie - użytkownicy systemów Questy
        <strong>
          {" "}
          korzystają z jednego z najbezpieczniejszych programów do zarządzania
          przedsiębiorstwem na rynku.
        </strong>
      </p>
    </Artykul>
  );
};

export default Wdrozenieiso27001;
